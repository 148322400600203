import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const DownloadRedirect = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const utmParams = searchParams.toString(); // Preserve UTM parameters
        
        const appStoreURL = `https://apps.apple.com/app/summie/id6737628622?${utmParams}`;

        window.location.href = appStoreURL;
    }, [location]);

    return <p>Redirecting to the App Store...</p>;
};

export default DownloadRedirect;